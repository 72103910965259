<template>
    <div class="content">
        <s-header :name="$t('LAN.question_detail')"></s-header>
        <div class="ques-content">
            <div class="ques-detail" v-if="item != null">
                <div class="title">
                    <h2 v-html="item.question"/>
                    <p> {{ date }} </p>
                </div>
                <div class="body">
                    <p v-html="item.answer"></p>
                </div>
                <!-- <div class="solve">
                    <van-divider>您的问题是否得到解决</van-divider>
                    <p>
                        <a href="javascript:;" class="like"><i></i>有用</a>
                        <a href="javascript:;" class="no-like"><i></i>没用</a>
                    </p>
                </div> -->
            </div>
        </div>
    </div>
   
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import sHeader from '@/components/Header'
import { useRoute } from 'vue-router'
 
export default {
    setup() {
        const route = useRoute()
        const state = reactive({
            date:"",
            item:null
        });

        onMounted(() => {
            init()
        });

        const init = async () => {
            state.item = route.query
        }

        return {
            ...toRefs(state),
        }
    },
    components: {
        sHeader
    },
}
</script>

<style lang="less" scoped>
.content {
    height: 100vh;
}
.ques-content {
    padding: 0 30px;
    text-align: left;
    .ques-detail {
        padding: 30px 0;

        .title {
            padding-left: 30px;
            background: url("../../../assets/helper/icon_ques.png") no-repeat left 0px;
            background-size: 24px;
            margin-bottom: 5px;

            h2 {
                font-weight: 400;
                font-size: 18px;
                color: #666;
            }

            p {
                padding: 5px 0;
                font-size: 18px;
                color: #b1b1b1;
            }
        }

        .body {
            padding-left: 30px;
            background: url("../../../assets/helper/icon_answer.png") no-repeat left 4px;
            background-size: 24px;
            font-size: 18px;
            color: #333;
            line-height: 30px;
        }

        .solve {
            text-align: center;
            p {
                padding: 4.267vw 0 5.333vw;
                a {
                    width: 24vw;
                    height: 8.8vw;
                    border-radius: 10.667vw;
                    border: .267vw solid #999;
                    font-size: 3.733vw;
                    color: #999;
                    margin: 0 4.667vw;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;

                    .like i {
                        background: url("../../../assets/helper/icon_like.png") no-repeat 50%;
                        background-size: cover;
                    }
                }
            }
        } 
    }
}

</style>

